<template>

  <div>
      <vs-row vs-justify="start">
        <vs-button color="primary" @click="addCategory" icon="add">Add Category</vs-button>
      </vs-row>
      <vs-row vs-justify="start" gutter="20" class="my-5 custom-row">
        <vs-col v-for="category in categories" :key="category.id" type="flex" class="mx-2" vs-justify="start" vs-align="center" vs-w="2.8" vs-sm="12" vs-xs="12">
          <vs-card>
            <vs-list>
            <vs-row class="flex justify-between items-baseline py-2">
                <h4 class="text-2xl px-2 text-primary">{{ category.category_name }}</h4>
                <div>
                  <feather-icon icon="EditIcon" class="text-warning cursor-pointer w-5" @click="editCategory(category.id)"></feather-icon>
                  <feather-icon icon="Trash2Icon" class="text-danger cursor-pointer w-5" @click="deleteCategory(category.id)"></feather-icon>
                </div>
            </vs-row>
            <div class="h-64 w-full overflow-y-auto">
              <div v-for="brand in category.brands" :key="brand.id">
                <vs-list-item :title="brand.brand_name">
                  <feather-icon icon="EditIcon" class="text-warning cursor-pointer w-5" @click="editBrand(category.id, brand.id)"></feather-icon>
                  <feather-icon icon="Trash2Icon" class="text-danger cursor-pointer w-5" @click="deleteBrand(category.id, brand.id)"></feather-icon>
                </vs-list-item>
              </div>
              <small v-if="category.brands.length < 1" class="flex justify-center my-3 capitalize">Brand {{ category.category_name }} belum tersedia</small>
              
            </div>
            </vs-list>
            <vs-button color="primary" icon="add" size="small" @click="addBrand(category.id)" class="w-full">Add Brand</vs-button>
          </vs-card>
        </vs-col>

      </vs-row>

      <vs-popup classContent="popup-example" :title="`${mode} ${type}`" :active.sync="popup">
          <div v-if="type == 'brand' && mode != 'delete'">
              <div class="vx-row">
                <div class="vx-col md:w-1/1 w-full">
                  <vs-input class="w-full mb-3 capitalize" :label="$i18n.t('Name Brand '+ formCategory.category_name)" v-model="formBrand.brand_name" />
                </div>
              </div>
            </div>
            
            <div v-if="type == 'category' && mode != 'delete'">
              <div class="vx-row">
                <div class="vx-col md:w-1/1 w-full">
                  <vs-input class="w-full mb-3 capitalize" :label="$i18n.t('Name Category')" v-model="formCategory.category_name" />
                </div>
              </div>
          </div>

          <div v-if="mode == 'delete'">
            <p>Apakah anda yakin akan menghapus {{ type }} {{ type == 'brand' ? formBrand.brand_name + ` pada category ${formCategory.category_name}?` : formCategory.category_name+ "? semua data brand dari category ini akan terhapus" }}</p>
          </div>


        <div class="mt-5">
        <vs-button :color="mode == 'delete' ? 'danger' : 'primary'" type="filled" icon-pack="feather" icon="icon-save" @click="storeItem">
          {{ mode == 'delete' ? $i18n.t('Delete') : $i18n.t('Save')  }}
        </vs-button>
    </div>
    </vs-popup>
    </div>
  
</template>
  
  <script>
  
  
  
  export default {
    components: {
      
    },
    data () {
      return {
        popup: false,
        mode: '',
        type: '',
        formCategory: {},
        formBrand: {},
        categories: []
      }
    },
    computed: {
      
    
    },
    methods: {
      addCategory(){
        this.popup = true
        this.mode = 'add'
        this.type = 'category'
        this.formCategory = {
          category_name: null,
          id: null
        }
      },
      addBrand(categoryId){
        this.popup = true
        this.mode = 'add'
        this.type = 'brand'
        this.formCategory = this.categories.find(data => data.id === categoryId)
        this.formBrand = {
          sparepart_category_id: categoryId,
          brand_name: null,
          id: null
        }
      },
      editCategory(categoryId){
        this.popup = true,
        this.mode = 'edit',
        this.type = 'category'
        this.formCategory = this.categories.find(data => data.id == categoryId)
      },
      editBrand(categoryId, brandId){
        this.popup = true,
        this.mode = 'edit',
        this.type = 'brand'
        this.formBrand = {
          sparepart_category_id: categoryId,
          brand_name: this.categories.find(data => data.id == categoryId).brands.find(data => data.id == brandId).brand_name,
          id: brandId
        }
      },
      deleteCategory(categoryId){
        this.popup = true
        this.mode = 'delete'
        this.type = 'category'
        this.formCategory = this.categories.find(data => data.id == categoryId)
        
      },
      deleteBrand(categoryId, brandId){
        this.popup = true
        this.mode = 'delete'
        this.type = 'brand'
        this.formBrand = {
          sparepart_category_id: categoryId,
          brand_name: this.categories.find(data => data.id == categoryId).brands.find(data => data.id == brandId).brand_name,
          id: brandId
        }
      },
      storeItem(){
        if (this.type == 'category') {
          switch(this.mode){
            case 'add':
              const formdata = new FormData()
              formdata.set("category_name", this.formCategory.category_name)
              this.$store.dispatch("sparepartCategory/storeCategory", formdata)
              .then(
                resp => {
                  this.$vs.notify({
                        title: "Successful",
                        text: resp.data.message,
                        iconPack: "feather",
                        icon: "icon-check-circle",
                        color: "success"
                      });
                    this.popup = false
                    this.getCategories()
                }
              ).catch(
                err => {
                  this.$vs.notify({
                        title: "Fail",
                        text: err.data.message,
                        iconPack: "feather",
                        icon: "icon-check-circle",
                        color: "danger"
                      });
                }
                )
              break;
            case 'edit':
            this.$store.dispatch("sparepartCategory/updateCategory", {
              body: {
                category_name: this.formCategory.category_name
              },
              id: this.formCategory.id
            }).then(
                resp => {
                  this.$vs.notify({
                    title: "Successful",
                    text: resp.data.message,
                    iconPack: "feather",
                    icon: "icon-check-circle",
                    color: "success"
                  });
                  this.popup = false
                  this.getCategories()
                }
              ).catch(
                err => {
                  this.$vs.notify({
                    title: "Fail",
                    text: err.data.message,
                    iconPack: "feather",
                    icon: "icon-check-circle",
                    color: "danger"
                  });
                }
              )
                break;
            case 'delete':
              this.$store.dispatch("sparepartCategory/deleteCategory", this.formCategory.id).then(
                resp => {
                  this.$vs.notify({
                    title: "Successful",
                    text: resp.data.message,
                    iconPack: "feather",
                    icon: "icon-check-circle",
                    color: "success"
                  });
                  this.popup = false
                  this.getCategories()
                }
              ).catch(
                err => {
                  this.$vs.notify({
                    title: "Fail",
                    text: err.data.message,
                    iconPack: "feather",
                    icon: "icon-check-circle",
                    color: "danger"
                  });
                }
              )
            break;
          }
          
        } else if (this.type == 'brand') {
          switch(this.mode){
            case 'add':
              const formData = new FormData()
              formData.set("brand_name", this.formBrand.brand_name)
              formData.set("sparepart_category_id", this.formBrand.sparepart_category_id)
              this.$store.dispatch("sparepartBrand/storeBrand", formData)
              .then(
                resp => {
                  this.$vs.notify({
                    title: "Successful",
                    text: resp.data.message,
                    iconPack: "feather",
                    icon: "icon-check-circle",
                    color: "success"
                  });
                  this.popup = false
                  this.getCategories()
                }
              ).catch(
                err => {
                  this.$vs.notify({
                    title: "Fail",
                    text: err.data.message,
                    iconPack: "feather",
                    icon: "icon-check-circle",
                    color: "danger"
                  });
                }
              )
              break;
            case 'edit':
            this.$store.dispatch("sparepartBrand/updateBrand", {
              id: this.formBrand.id,
              body: {
                brand_name: this.formBrand.brand_name,
                sparepart_category_id: this.formBrand.sparepart_category_id
              }
            })
              .then(
                resp => {
                  this.$vs.notify({
                    title: "Successful",
                    text: resp.data.message,
                    iconPack: "feather",
                    icon: "icon-check-circle",
                    color: "success"
                  });
                  this.popup = false
                  this.getCategories()
                }
              ).catch(
                err => {
                  this.$vs.notify({
                    title: "Fail",
                    text: err.data.message,
                    iconPack: "feather",
                    icon: "icon-check-circle",
                    color: "danger"
                  });
                }
              )
              break;
            case 'delete':
            this.$store.dispatch("sparepartBrand/deleteBrand", this.formBrand.id)
              .then(
                resp => {
                  this.$vs.notify({
                    title: "Successful",
                    text: resp.data.message,
                    iconPack: "feather",
                    icon: "icon-check-circle",
                    color: "success"
                  });
                  this.popup = false
                  this.getCategories()
                }
              ).catch(
                err => {
                  this.$vs.notify({
                    title: "Fail",
                    text: err.data.message,
                    iconPack: "feather",
                    icon: "icon-check-circle",
                    color: "danger"
                  });
                }
              )
                break;
          }
        }
      },
      getCategories(){
        this.$store.dispatch("sparepartCategory/getCategories")
        .then(resp => {
          this.categories = resp.data.values
        }).catch(
          err => {
            this.$vs.notify({
              title: "Fail",
              text: err.data.message,
              iconPack: "feather",
              icon: "icon-check-circle",
              color: "danger"
            });
          }
        )
      }
      
    },
    mounted () {
      
    },
    watch: {
      
    },
    created () {
      this.getCategories()
    }
  }
  
  </script>
  
  <style lang="scss">
  #page-user-list {
    .user-list-filters {
      .vs__actions {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-58%);
      }
    }
  }
  // .vs-popup--content {
  //   overflow: visible !important;
  
  // }
  .vs-popup--content {
    // overflow: visible;
    max-height: 600px; /* Adjust the max-height according to your needs */
    overflow-y: auto;
  }
  .picture {
    width: 200px;
    height: 250px;
  }
  .custom-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}
  </style>
  